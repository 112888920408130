import React from 'react';

import Appbar from '../components/appbar';

import pictureOfMe from '../images/1.jpg';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

class About extends React.Component {

  render(){
    return(
      <div>
        <Appbar />
        <Container>
          <Row>
            <Col xs={{size:12}} lg={{size: 8, offset:2}}>
              <img src={pictureOfMe} className="PictureOfMe" alt="Me" />
              <p>Hi there, my name is Scott Hansen. Welcome to my website! I'm an associate software engineering developer 
                  currently employed at JP Morgan Chase & Co. 
              </p>

              <p>
                  I'm passionate about problem solving and creating solutions with great teams. I believe that 
                  any carefully crafted software team can achieve far more than the sum of it's individuals.
              </p>
              
              <p>
                Check out where I am right now on <a href="https://www.linkedin.com/in/scottlhansen/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </p>
            </Col>
          </Row>
          
        </Container>
      </div>
    );
  }
}

export default About;